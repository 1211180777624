<template>
	<div class="edit-form-container">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form form-type-2"
			:model="formData"
			:rules="rules"
		>
			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Название тэга (ru)" prop="title_ru">
						<el-input v-model="formData.title_ru" />
					</el-form-item>
				</div>

				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Назва тега (uk)" prop="title_uk">
						<el-input v-model="formData.title_uk" />
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item prop="color_code"
						label="Цвет"
						class="color-picker-wrapper"
					>
						<el-color-picker v-model="formData.color_code"></el-color-picker>
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-6 mcol-sm-3 ">
					<el-form-item label="Тэг активен" prop="is_publiс">
						<el-switch
							v-model="formData.is_publiс"
							:active-value="1"
							:inactive-value="0"
						/>
					</el-form-item>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';

import { required } from '@/constants/validation';
import { itemFormMixin } from '@/mixins';

export default {
	mixins: [itemFormMixin],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			formData: {
				title_ru: '',
				title_uk: '',
				color_code: '',
				is_publiс: false,
			}
		};
	},

	computed: {
		rules: () => ({
			title_ru: required,
			title_uk: required
		})
	},

	methods: {}
};
</script>
